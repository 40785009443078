import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
// Assets
import Esto from "../../assets/img/projects/esto.png";
import Stebby from "../../assets/img/projects/stebby.png";
import Insur from "../../assets/img/projects/insur.png";
import Affinant from "../../assets/img/projects/affinant.png";

export default function Projects() {
  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Projects</h1>
            <p className="font13">
              At Rebing OÜ, we're proud to have partnered with innovative companies across various industries. Our projects 
              <br />
              showcase our commitment to delivering cutting-edge solutions that drive business growth and efficiency.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
              <ProjectBox
                img={Esto}
                text="Built and maintained the infrastructure up the leading fintech provider in the Baltics - ESTO."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
              <ProjectBox
                img={Stebby}
                text="Improved the core infrastructure of Stebby, formerly known as SportID."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
              <ProjectBox
                img={Insur}
                text="Helped with building the architecture of an innovative insurtech."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
              <ProjectBox
                img={Affinant}
                text="Consulted on setting up the infrastructure for an affiliate company."
                action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={AddImage2} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">A few words about company</h4>
              <h2 className="font40 extraBold">A Study of Creativity</h2>
              <p className="font12">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
                diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              </p>
              <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                <div style={{ width: "190px" }}>
                  <FullButton title="Get Started" action={() => alert("clicked")} />
                </div>
                <div style={{ width: "190px", marginLeft: "15px" }}>
                  <FullButton title="Contact Us" action={() => alert("clicked")} border />
                </div>
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div> */}
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
