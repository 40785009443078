import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Assets
import EstoLogo from "../../assets/img/clients/esto.svg"
import StebbyLogo from "../../assets/img/clients/stebby.png";
import AffinantLogo from "../../assets/img/clients/affinant.png";
import InsurLogo from "../../assets/img/clients/insur.png";

export default function ClientSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper logoSrc={EstoLogo} className="flexCenter" />
        <LogoWrapper logoSrc={StebbyLogo} className="flexCenter" />
        <LogoWrapper logoSrc={InsurLogo} className="flexCenter" />
        <LogoWrapper logoSrc={AffinantLogo} className="flexCenter" />
        <LogoWrapper logoSrc={EstoLogo} className="flexCenter" />
        <LogoWrapper logoSrc={StebbyLogo} className="flexCenter" />
        <LogoWrapper logoSrc={InsurLogo} className="flexCenter" />
        <LogoWrapper logoSrc={AffinantLogo} className="flexCenter" />
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 100%;
  height: 50px; // Adjust this value as needed
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
  background-image: url(${props => props.logoSrc});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;